.section-top-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid white 1px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.section-inner-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px 35px;
  justify-content: center;
  align-items: center;
}

.button-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

button {
  border-radius: 30px;
  padding: 7px 15px;
}

@media (min-width: 768px) {
  #text {
    font-size: larger;
    width: 60%;
    padding: 8px;
  }

  .button-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
}
