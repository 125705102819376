.event-card-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}

.event-card-wrapper {
    flex: 1 1 calc(25% - 16px);
    max-width: calc(25% - 16px);
    box-sizing: border-box;
}

.event-card {
    width: 100%;
    position: relative;
    background-color: #1f1f1f;
    border-radius: 8px;
    overflow: hidden;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.event-card-image-container {
    width: 100%;
    padding-top: 100%; /* Makes the container square */
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.event-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d3d3d3;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

.event-card-content-box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
}

.event-card-title {
    color: white;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
    margin-bottom: 0;
    cursor: pointer;
}

.event-card-details {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgb(200, 200, 200);
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.event-card-space-name, .event-card-world-name {
    color: rgb(200, 200, 200);
    font-family: 'Work Sans';
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
}

.event-card-separator {
    font-size: 6px;
    vertical-align: middle;
}

@media (max-width: 1360px) {
    .event-card-wrapper {
        flex: 1 1 calc(33.33% - 16px);
        max-width: calc(33.33% - 16px);
    }
}

@media (max-width: 1024px) {
    .event-card-wrapper {
        flex: 1 1 calc(50% - 16px);
        max-width: calc(50% - 16px);
    }
}

@media (max-width: 768px) {
    .event-card-wrapper {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

@media (max-width: 1250px) {
    .event-card-title {
        font-size: 16px;
    }
    .event-card-details {
        font-size: 12px;
    }
}