/* Styles for the card collection container */
.card-collection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

/* Styles for each card wrapper */
.card-wrapper {
  flex: 1 1 calc(25% - 16px); /* For desktop: four cards per row, with a gap */
  max-width: calc(25% - 16px);
  box-sizing: border-box;
}

@media (max-width: 1360px) {
  .card-wrapper {
      flex: 1 1 calc(33.33% - 16px); /* For tablets: three cards per row */
      max-width: calc(33.33% - 16px);
  }
}

@media (max-width: 1024px) {
  .card-wrapper {
      flex: 1 1 calc(50% - 16px); /* For tablets: two cards per row */
      max-width: calc(50% - 16px);
  }
}

@media (max-width: 768px) {
  .card-wrapper {
      flex: 1 1 100%; /* For mobile: one card per row */
      max-width: 100%;
  }
}

/* Styles for the card itself */
.card {
  width: 100%;
  position: relative;
  background-color: #1f1f1f;
  border-radius: 8px;
  overflow: hidden;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 8px; /* Gap between card-image and content-box */
}

/* Styles for the placeholder image */
.card-image {
  width: 100%;
  padding-bottom: 56.25%; /* Aspect ratio: 16:9 */
  background-color: #d3d3d3; /* Placeholder background */
  border-radius: 8px;
  cursor: pointer;
}

/* Styles for the content box */
.content-box {
  display: flex;
  flex-direction: column;
  gap: 0px; /* Gap between title and world name */
}

/* Styles for the card title */
.card-title {
  color: white;
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 20px;
  text-align: left;
  margin-bottom: 0px;
  cursor: pointer;
}

/* Styles for the world name */
.world-name, .space-name {
  color: rgb(200, 200, 200);
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

@media (max-width: 1250px) {
  .card-title {
      font-size: 16px;
  }
  .world-name {
      font-size: 14px;
  }
}
