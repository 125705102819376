body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f1f;
  height: 100vh; /* Ensure the body takes up full viewport height */
  width: 100vw; /* Ensure the body takes up full viewport width */
  overflow: hidden; /* Hide overflow */
}

.icon-white path {
  fill: #FFFFFF;
}

html,body {
  margin: 0px;
  height: 100%;
  overflow: hidden;
}
    /* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}

input[type="color"] {
  border-radius: 8px;
  padding: 0;
  border: 1px solid #a4a4a4;
  cursor: pointer;
  overflow: hidden;
}

input[type="color"]::-moz-color-swatch {
  border: none;
  overflow: hidden;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}
