/* Styles for the card collection container */
.temp-market-card-collection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }
  
  /* Styles for each card wrapper */
  .temp-market-card-wrapper {
    flex: 1 1 calc(25% - 16px); /* For desktop: four cards per row, with a gap */
    max-width: calc(25% - 16px);
    box-sizing: border-box;
  }
  
  @media (max-width: 1360px) {
    .temp-market-card-wrapper {
      flex: 1 1 calc(33.33% - 16px); /* For tablets: three cards per row */
      max-width: calc(33.33% - 16px);
    }
  }
  
  @media (max-width: 1024px) {
    .temp-market-card-wrapper {
      flex: 1 1 calc(50% - 16px); /* For tablets: two cards per row */
      max-width: calc(50% - 16px);
    }
  }
  
  @media (max-width: 768px) {
    .temp-market-card-wrapper {
      flex: 1 1 100%; /* For mobile: one card per row */
      max-width: 100%;
    }
  }
  
  /* Styles for the card itself */
  .temp-market-card {
    width: 100%;
    position: relative;
    background-color: #1f1f1f;
    border-radius: 8px;
    overflow: hidden;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 8px; /* Gap between card-image and content-box */
  }
  
  /* Styles for the placeholder image */
  .temp-market-card-image {
    width: 100%;
    padding-bottom: 90%; /* Aspect ratio: 16:9 */
    background-color: #d3d3d3; /* Placeholder background */
    border-radius: 8px;
  }
  
  /* Styles for the content box */
  .temp-market-content-box {
    display: flex;
    flex-direction: column;
    gap: 0px; /* No gap for a simple title */
    padding: 8px; /* Add padding for better spacing */
  }
  
  /* Styles for the card title */
  .temp-market-card-title {
    color: white;
    font-family: 'Work Sans';
    font-weight: 500;
    font-size: 20px;
    text-align: left;
  }
  