.profile-page {
  padding: 20px;
  background-color: #1f1f1f;
  color: white;
  font-family: Arial, sans-serif;
  width: 100%;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.profile-name-display {
  font-size: 24px;
  margin: 0;
  color: white;
}
.profile-username-display {
  font-size: 20px;
  margin: 0;
  color: white;
}

.profile-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.profile-page-image-placeholder {
  width: 80px;
  height: 80px;
  background-color: grey;
  border-radius: 50%;
}

.profile-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-name,
.profile-username {
  font-size: 24px;
  margin: 0;
  border: none;
  background: none;
  color: white;
  padding: 5px 0;
  border-radius: 12px;
}

.connections {
  margin-top: 8px;
  font-size: 18px;
  color: gray;
}

.profile-section {
  margin-top: 24px;
}

.profile-bio,
.profile-instagram,
.profile-twitter {
  width: 100%;
  font-size: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 8px;
  background-color: rgba(31, 31, 31, 1);
  color: white;
}

.profile-subsection {
  width: 48%;
}

.edit-button {
  background-color: #fff;
  color: white;
  border-radius: 12px;
  padding: 8px 20px;
  border-radius: 12px;
}

.edit-button-container {
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: end;
}

.edit-button:hover {
  background-color: #f0f0f0;
}

.customize-avatar-btn {
  background-color: #d9e4ec;
  color: black;
  font-family: Work Sans;
  font-size: 20px;
  font-weight: 500;
  padding: 8px 64px;
  border: 'none';
  border-radius: 24px;
  cursor: pointer;
  opacity: 1;
}

@media (max-width: 1024px) {
  .customize-avatar-btn {
    font-size: 16px;
  }
  .profile-name-display,
  .profile-username-display {
    font-size: 20px;
  }
}